<template>
  <Panel>
    <template #title><span>Put Shipment</span></template>
    <template #content>
      <el-form
        :model="ruleForm"
        :rules="rules"
        status-icon
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="Shelf Name" prop="shelf_name" class="first-item">
          <el-input
            type="text"
            v-model="ruleForm.shelf_name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <div class="muli-tags" @click="focus">
          <div>
            <el-button
              v-for="(tag, index) in tags"
              :key="index"
              size="mini"
              class="id-btn"
              plain
            >
              {{ tag
              }}<span class="del" @click="delTag(index)">X</span></el-button
            >
          </div>
        </div>

        <div class="input-cont">
          <div class="trackid">Tracking Id</div>
          <input type="text" ref="input" v-model="current" @keyup.enter="add" />
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 10px"
            @click="addput"
            >Enter</el-button
          >
          <div class="tip">
            You can enter multiple ids and please press Enter to confirm each
            one.
          </div>
        </div>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            :disabled="flag"
            >Submit</el-button
          >
          <el-button @click="resetForm('ruleForm')">Reset</el-button>
        </el-form-item>
      </el-form>
    </template>
  </Panel>
</template>

<script>
import { putshipment } from '../../api/user'
export default {
  data() {
    var validatval = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please fill in.'))
      } else {
        callback()
      }
    }
    return {
      tags: [],
      current: '',
      flag: false,
      ruleForm: {
        tracking_id: '',
        shelf_name: ''
      },
      rules: {
        shelf_name: [{ validator: validatval, trigger: 'blur' }]
      }
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    addput() {
      this.add()
    },
    add(e) {
      // const val = e.target.value
      const val = this.current
      if (!val) return
      // 如果已经存在相同tag，不再添加
      if (this.tags.indexOf(val) > -1) {
        this.$message({
          message: 'The tracking id already exists.',
          type: 'error'
        })
        return
      }
      // 把输入值添加到tag，并清空文本框
      this.tags.push(val)
      this.current = ''
    },
    // 删除点击的标签
    delTag(index) {
      this.tags.splice(index, 1)
    },
    submitForm(formName) {
      if (this.flag) {
        return
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.flag = true
          const data = await putshipment({
            tracking_id: this.tags,
            shelf_name: this.ruleForm.shelf_name
          })
          if (data.status === 201) {
            this.tags = []
            this.ruleForm.shelf_name = ''
            setTimeout(() => {
              this.flag = false
            }, 2000)
          } else {
            this.$message({
              message: data.data.toperr,
              type: 'error'
            })
            setTimeout(() => {
              this.flag = false
            }, 2000)
          }
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.tags = []
    }
  }
}
</script>

<style lang="less" scoped>
.del {
  margin-left: 3px;
  display: inline-block;
  padding: 2px 3px;
  color: #c0c4cc;
  &:hover {
    background: #f56c6c;
    color: #fff;
  }
}
.first-item {
  margin-top: 20px;
}
.el-form-item {
  margin-bottom: 20px;
}
.trackid {
  text-align: right;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding-right: 12px;
  width: 88px;
  color: #606266;
}
.input-cont {
  display: flex;
  margin-bottom: 40px;
  box-sizing: border-box;
  position: relative;
  .tip {
    position: absolute;
    top: 50px;
    left: 100px;
    color: #e6a23c;
  }
  input {
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    padding: 0 15px;
    height: 40px;
    outline: none;
    flex: 1;
    &::placeholder {
      color: #c0c4cc;
    }
  }
}
.muli-tags {
  margin-left: 90px;
  padding: 11px;
}
.id-btn {
  margin: 0 10px 10px 0;
}
</style>
